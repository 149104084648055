//Global variable for spellchecker
var l;

var bw2_PreviewInLineSelected = 0;
var bw2_LastIdContainer='';

function formatBlockCode(){
    document.querySelectorAll('pre').forEach((block) => { 
        hljs.highlightBlock(block); 
    }); 
}

function getHammerInstance(id,globalCount)
{
    var element = document.getElementById('inline_preview_'+id);
    if(element)
    {
        element.id='inline_preview_'+id+"_"+globalCount;
        return new Hammer(element);
    }
    return undefined;
}

function bw2_displayInLinePreview(id, content, guid, idButton) {
    if(bw2_LastIdContainer != '')
    {
        var e = document.getElementById(bw2_LastIdContainer);
        if(e!=undefined)
        {
            e.innerHTML = "";
            e.style.display = 'none';
        }
    }

    var e = document.getElementById(guid +'_'+ id + '-container');
    var button = document.getElementById(idButton);

    bw2_PreviewInLineSelected = id
    bw2_LastIdContainer=guid +'_'+ id + '-container';
    e.innerHTML = content;
    e.style.display = 'block';
    if (idButton != '') {
        button.style.background = "#E7F4FC";
    }

    bw2_PreviewTitleChangeColor(guid, '#436E98');
}

function bw2_PreviewTitleChangeColor(guid, color) {
    if (document.getElementById('PreviewTitle' + guid) != null) {
        var title = document.getElementById('PreviewTitle' + guid);
        title.style.color = color;
        title.style.borderBottomColor = color;
    }
}

function bw2_displayInLineFeedPreview(content, action) {
    var e = document.getElementById("bw2_PreviewFeed");
    var c = document.getElementById("bw2_MainContent");

    if (action == 'show') {
        c.style.display = 'none';
        e.innerHTML = content;
        e.style.display = 'block';
    }
    else {
        c.style.display = 'block';
        e.style.display = 'none';
        e.innerHTML = '';
    }
}



function bw2_executeOpenPreviewInline(id, boid, guid, IdButton, ParentPreview_SelectedID) {
    var idContainer = guid +'_'+id+'-container'
    if (typeof arguments === 'undefined' || guid != '') {

        //unselect all the buttons of the preview plugin
        if (IdButton != '') {

            var button = document.getElementById(IdButton);

            var arr = IdButton.split("_");
            var x = document.querySelectorAll(".bw2linkButton");
            var i;
            for (i = 0; i < x.length; i++) {
                if (x[i].id.startsWith(arr[0])) {
                    x[i].style.backgroundColor = "#f7f7f9";
                }
            }
        }

        if (bw2_PreviewInLineSelected == id) {
            var e = document.getElementById(idContainer);
            e.innerHTML = "";
            bw2_PreviewInLineSelected = 0
            e.style.display = 'none';
            if (IdButton != '') {
                button.style.background = "#f7f7f9";
            }
        }
        else {
            bw2_PreviewTitleChangeColor(guid, 'purple');
            window.CardDetailPage.BW2Mobile_OpenPreviewInline(id, boid, guid, IdButton, ParentPreview_SelectedID);
        }

    }
}


function bw2_CloseInLineFeedPreview(id) {
    var e = document.getElementById("bw2_PreviewFeed");
    var c = document.getElementById("bw2_MainContent");

    c.style.display = 'block';
    e.style.display = 'none';
    e.innerHTML = '';
    
}

function tooglePlugin(pluginGUID) {
    if (document.getElementById(pluginGUID).style.display == 'none') {
        document.getElementById(pluginGUID).style.display = 'block';
        document.getElementById('arrow-' + pluginGUID).setAttribute("class", "fa fa-angle-down");
    } else {
        document.getElementById(pluginGUID).style.display = 'none';
        document.getElementById('arrow-' + pluginGUID).setAttribute("class", "fa fa-angle-up");
    }
}

function bw2_executeMethodFromBO(pluginGuid, methodName, arguments) {
    console.warn('not implemented');
}

function  bw2_executeMethodFromPlugin(param1,param2,param3,param4,param5){
    console.warn('not implemented');
} 